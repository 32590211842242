const $ = require('jquery');

import {redirectWithParams, translate} from './functions';


$(function() {

    let dashboardAjax = [];

    //Thx to stack
    function getUrlParameter(sParam) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
        return null;
    }

    function loadNbLead(url, bloc) {
        let seller = getUrlParameter('seller');
        let data = {
            justcount: true,
            bloc: bloc
        };
        if (seller != null && seller !== "") {
            data['seller'] = seller;
        }
        let req = $.ajax({
            url: url,
            data: data
        })
            .done(function (data) {
                if (typeof data.blocks != 'undefined') {
                    for (let i = 0; i < data.blocks.length; i++) {
                        let selector = data.blocks[i].selector;
                        let value = data.blocks[i].nb;
                        //Si ce sont des taux et non pas des leads
                        if (selector === 'transferRateData') {
                            let transfer_rate_chart = $("#transferRateChart");
                            if(typeof transfer_rate_chart !== 'undefined' && typeof transfer_rate_chart != 'undefined'){
                                if (data.blocks[i].data.rate == null) {
                                    $('#transferPieChart').replaceWith('<div class="no-data" id="transferPieChart">'+translate('crm.stats.no_data')+'</div>')
                                } else {
                                    let chartData = data.blocks[i].data.labels;
                                    let transferLabels = [data.blocks[i].data.rate, 100 - data.blocks[i].data.rate];
                                    if (typeof getTransferRate === 'function') {
                                        getTransferRate(chartData, transferLabels);
                                    }
                                }
                            }
                            if (data.blocks[i].data.rate != null) {
                                let elem = $('#transferRate');
                                elem.text(data.blocks[i].data.rate + '%');
                                let elem2 = $('#transferRate2');
                                elem2.text(' : ' + data.blocks[i].data.rate + '%');
                            }
                        }
                        else {
                            $('a[data-type="' + selector + '"] .nb').text(value);
                        }
                    }
                }
            });
        dashboardAjax.push(req);
    }

    $('#mainMenu a').not('#popupBanner').click(function() {
        for (let i = 0; i < dashboardAjax.length; i++) {
            dashboardAjax[i].abort();
        }
    });

    if ($('.section.dashboard').length > 0) {
        loadNbLead(redirectWithParams(false, undefined, true), 'nb-dashboard');
        loadNbLead(redirectWithParams(false, undefined, true), 'nb-waitingResult');
        loadNbLead(redirectWithParams(false, undefined, true), 'nb-toCome');
    }
});


