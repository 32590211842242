const $ = require('jquery');
const {launchTooltip, translate} = require("./functions");

$(function () {
    const createNewLeaveBtn = $('.create-leave');
    let deleteLeaveBtn = $('.delete-leave');

    initializeDateTimePicker();
    handleCreateNewLeave();
    handleDeleteLeave();
    handleLeaveCommentActions();

    function initializeDateTimePicker()
    {
        let startDate = new Date()
        startDate.setHours(8,0,0);

        let endDate = new Date()
        endDate.setHours(19,0,0);

        $('.datetimepicker.start').datetimepicker({
            minDate: new Date(),
            startDate: startDate,
            format: 'd/m/Y H:i',
            validateOnBlur: false,
            step: 30,
            minTime: '8',
            maxTime: '19:30'
        })
        $('.datetimepicker.end').datetimepicker({
            minDate: new Date(),
            startDate: endDate,
            format: 'd/m/Y H:i',
            validateOnBlur: false,
            step: 30,
            minTime: '8',
            maxTime: '19:30'
        })
    }

    function handleCreateNewLeave()
    {
        createNewLeaveBtn.click(function(){
            const wrapper = $(this).parents('.seller-form-leave-wrapper');
            let section = $(this).parents('.seller-leave-list-section');
            let leaveStartTimeInput = wrapper.find('.start');
            let leaveEndTimeInput = wrapper.find('.end');
            let leaveCommentInput = wrapper.find('.comment');
            let leaveList = section.find('.seller-leave-list')
            let leaveErrorWrapper = section.find('.leave-error');
            const loader = section.find('.common-loader');
            if (leaveStartTimeInput.val() !== "" && leaveEndTimeInput.val() !== "") {
                const url = $(this).data('href');
                const userId = $(this).data('user-id');

                loader.removeClass('--hidden');
                leaveList.addClass('--hidden');

                $.post(
                    url,
                    {
                        userId: userId,
                        startTime: leaveStartTimeInput.val(),
                        endTime: leaveEndTimeInput.val(),
                        comment: leaveCommentInput.val(),
                    }
                )
                    .done(function( data ) {
                        if (data.error !== undefined) {
                            loader.addClass('--hidden');
                            leaveList.removeClass('--hidden');
                            leaveErrorWrapper.html(data.error)
                        } else {
                            leaveList.html(data.template)
                            leaveList.removeClass('--hidden');
                            loader.addClass('--hidden');
                            leaveErrorWrapper.html('');
                            clearLeaveForm(wrapper);
                            deleteLeaveBtn = $('.delete-leave');
                            handleDeleteLeave();
                            handleLeaveCommentActions();
                            handleSellerPresent(section, data.absent);
                            launchTooltip();
                        }
                    });
            }
        })
    }

    function handleDeleteLeave()
    {
        deleteLeaveBtn.click(function(e){
            let section = $(this).parents('.seller-leave-list-section');
            let leaveList = section.find('.seller-leave-list')
            const loader = section.find('.common-loader');
            let leaveErrorWrapper = section.find('.leave-error');
            e.preventDefault();
            if (confirm(translate('crm.public.are_you_sure'))) {
                const leaveId = $(this).data('leave-id');
                const userId = $(this).data('user-id');
                const url = $(this).data('href');

                loader.removeClass('--hidden');
                leaveList.addClass('--hidden');

                $.post(
                    url,
                    {
                        userId: userId,
                        leaveId: leaveId,
                    }
                )
                    .done(function( data ) {
                        leaveList.html(data.template)
                        leaveList.removeClass('--hidden');
                        loader.addClass('--hidden');
                        leaveErrorWrapper.html('');
                        deleteLeaveBtn = $('.delete-leave');
                        handleDeleteLeave();
                        handleLeaveCommentActions();
                        handleSellerPresent(section, data.absent);
                    });
            }
        });
    }

    function handleLeaveCommentActions()
    {
        handleDisplayLeaveLineCommentForm();
        handleSubmitLeaveLineCommentForm();
    }

    function handleSellerPresent(section, absent)
    {
        if (absent) {
            section.find('.icon-cancel-circle').removeClass('--hidden');
            section.find('.icon-checkmark-circle').addClass('--hidden');
        } else {
            section.find('.icon-checkmark-circle').removeClass('--hidden');
            section.find('.icon-cancel-circle').addClass('--hidden');
        }
    }

    function handleDisplayLeaveLineCommentForm()
    {
        $('.seller-leave-line__comment-info').click(function(){
            let lineCommentInput = $(this).siblings('.seller-leave-line__comment-input');
            let lineIpdateCommentBtc = $(this).parent().parent().find('.update-comment-leave');
            $(this).addClass('--hidden');
            lineCommentInput.removeClass('--hidden');
            lineIpdateCommentBtc.removeClass('--hidden');
        });
    }

    function handleSubmitLeaveLineCommentForm()
    {
        $('.update-comment-leave').click(function(){
            let url = $(this).data('href');
            let leaveId = $(this).data('leave-id');
            let userId = $(this).data('user-id');
            let newComment = $(this).parent().parent().find('.seller-leave-line__comment-input').val();

            let section = $(this).parents('.seller-leave-list-section');
            let leaveList = section.find('.seller-leave-list')
            let leaveErrorWrapper = section.find('.leave-error');
            const loader = section.find('.common-loader');

            loader.removeClass('--hidden');
            leaveList.addClass('--hidden');

            $.post(
                url,
                {
                    leaveId,
                    userId,
                    newComment,
                }
            )
                .done(function(data) {
                    leaveList.html(data.template)
                    leaveList.removeClass('--hidden');
                    loader.addClass('--hidden');
                    leaveErrorWrapper.html('');
                    deleteLeaveBtn = $('.delete-leave');
                    handleDeleteLeave();
                    handleLeaveCommentActions();
                });
        })
    }

    function  clearLeaveForm(wrapper)
    {
        wrapper.find('.start').val('');
        wrapper.find('.end').val('');
        wrapper.find('.comment').val('');
    }
});
