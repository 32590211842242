const jQuery = require('jquery');
const {translate} = require("../functions");

/**
 * Select2 French translation
 */
(function ($) {
    "use strict";

    if ($.fn.select2) {
        $.fn.select2.locales['fr'] = {
            formatMatches: function (matches) { return matches + " résultats sont disponibles, utilisez les flèches haut et bas pour naviguer."; },
            formatNoMatches: function () { return translate('crm.public.no_matches'); },
            formatInputTooShort: function (input, min) { let n = min - input.length; return "Saisissez " + n + " caractère" + (n === 1? "" : "s") + " supplémentaire" + (n == 1? "" : "s"); },
            formatInputTooLong: function (input, max) { let n = input.length - max; return "Supprimez " + n + " caractère" + (n === 1? "" : "s"); },
            formatSelectionTooBig: function (limit) { return "Vous pouvez seulement sélectionner " + limit + " élément" + (limit === 1 ? "" : "s"); },
            formatLoadMore: function (pageNumber) { return "Chargement de résultats supplémentaires…"; },
            formatSearching: function () { return "Recherche en cours…"; }
        };

        $.extend($.fn.select2.defaults, $.fn.select2.locales['fr']);
    } else {
        console.warn('Select2 is not loaded correctly.');
    }
})(jQuery);