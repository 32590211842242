const $ = require('jquery');

////////////////
// FUNCTIONS ///
////////////////

export async function managePlayerSection(playerSection)
{
    let callsUrls = await fetchViadCallUrls(playerSection)

    if (callsUrls && callsUrls.length && Array.isArray(callsUrls)) {
        constructPlayerSelect(playerSection, callsUrls);
        constructPlayers(playerSection, callsUrls);
        handlePlayerSelectChange(playerSection);
        showPlayerSection(playerSection);
        $('.player-loader').remove();
    } else {
        removePlayerSection(playerSection);
    }
}

function fetchViadCallUrls(playerSection)
{
    let url = playerSection.data('url');

    return $.get(url,function(data){
        return data;
    })
        .fail(function(){
            return null;
        })
        ;
}

function constructPlayerSelect(playerSection, callsUrls)
{
    let select = playerSection.find('.call-player-select');

    callsUrls.forEach(function(value, index){
        let selected = index === 0 ? 'selected' : '';
        select.append(`
                <option value="${index}" ${selected}>
                   Conv. ${index + 1}
                </option>
            `)
    })

    select.removeClass('--hidden');
    select.select2();
}

function constructPlayers(playerSection, callsUrls)
{
    let playersWrapper = playerSection.find('.players-wrapper');
    let ctiUse = playersWrapper.data('cti-use');
    let audioSourcePrefix = ctiUse === 'viaflow' ? 'data:audi/mp3;base64,' : '';

    callsUrls.forEach(function(value, index){
        let display = index !== 0 ? ' --hidden' : '';
        playersWrapper.append(`
                <audio controls="" class="audio-player player${index}${display}" controlslist="nodownload">
                    <source src="${audioSourcePrefix}${value}" type="audio/mp3">
                    <span>Votre navigateur ne prend pas en charge l'audio HTML.</span>
                </audio>
            `)
    })
}

function handlePlayerSelectChange(playerSection)
{
    let select = playerSection.find('.call-player-select');
    let players = playerSection.find('.audio-player');

    select.change(function() {
        players.each(function(){
            this.pause()
            $(this).addClass('--hidden');
        });
        playerSection.find('.player'+$(this).val()).removeClass('--hidden');
    });
}

function showPlayerSection(playerSection)
{
    playerSection.parent().removeClass('--hidden');
    playerSection.parents('.record-section').removeClass('--hidden');
}

function removePlayerSection(playerSection)
{
    playerSection.parent().remove();
    sectionsNumbering();
}

function sectionsNumbering()
{
    let sections = $('.section-player');
    sections.each(function(index){
        let titleText = $.trim($(this).find('.title').text()).split(" ")[0];
        $(this).find('.title').text(titleText + ' ' + ( index + 1 ));
    })
}

$(function(){

    const callPlayersSections = $('.new-players-section');

    callPlayersSections.each(function (){
        managePlayerSection($(this));
    })
});