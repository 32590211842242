const $ = require('jquery');

$(function(){
    const textarea = $('.lead-note-form textarea');
    const submitBtn = $('.lead-note-form .submit-comment');
    const listNoteWrapper = $('.lead-note-form .lead-note-list-wrapper');
    const loaderWrapper = $('.lead-note-form .common-loader');

    handleSubmitNote();

    function handleSubmitNote()
    {
        submitBtn.click(function(){
            const newNoteContent = textarea.val();
            if (newNoteContent.length > 0) {
                listNoteWrapper.html('');
                loaderWrapper.removeClass('--hidden');
                let note_new_url = Routing.generate('note_new');
                $.post( note_new_url, { noteContent: newNoteContent, leadId : $('#value_lead_id').val()})
                    .done(function( response ) {
                        listNoteWrapper.html(response.template);
                        loaderWrapper.addClass('--hidden');
                        textarea.val('');
                    });
            }
        });
    }
});
