const $ = require('jquery');

import tippy from 'tippy.js';
import {
    customForm,
    handlePopinAction,
    popLink,
    loadMultipleSelect,
    loadSellersList,
    handleChangePos,
    setCookie,
    openConfirmation, submitLinks, translate
} from './functions';

export function closeWindow() {
    $('.--close-window').on('click', function() {
        self.close();
    })
}
const applyCti = function () {
    let cti_use = $('#cti_use').data('cti');
    if (cti_use === 'viadialog') {
        $('.confirm-call').on('click', function (event) {
            event.preventDefault();
            return confirm("Etes-vous sûr de vouloir appeler ce numéro ?");
        });
    } else if (cti_use === 'onc') {
        $('.confirm-call').click(function (event) {
            let num = $(this).data('num');

            window.external.Rcp2HtmlTabMakeCall(num, null);
            event.preventDefault();
        });
    }
};

const hideProInfo = function () {

    const isPro = $('#lead_customer_pro, #contact_customer_pro');

    //Initial display
    if (isPro.is(":checked")) {
        $('.is-pro').removeClass('--hidden');
    }

    isPro.change(function(){
        let isChecked = $(this).is(":checked");
        if (isChecked === true) {
            $('.is-pro').removeClass('--hidden');
        } else {
            $('.is-pro').addClass('--hidden');
        }
    });
};
const showData = function (element) {
    element.addClass('--hidden');
    element.parents('.data').find('.content').removeClass('--hidden');
    element.parent().find('.hide-data').removeClass('--hidden');
};
const hideData = function (element) {
    element.addClass('--hidden');
    element.parents('.data').find('.content').addClass('--hidden');
    element.parent().find('.show-data').removeClass('--hidden');
};

$(function () {

    // compteur jda
    const jdaCounter = $('.nb-unanswered-call');
    if (jdaCounter.length > 0) {
        $.get(Routing.generate('jda_get_nb_call_to_treat'), function (data) {
            jdaCounter.html(data);
        });
    }
    // compteur email
    const emailCounter = $('.nb-emails-to-treat');
    if (emailCounter.length > 0) {
        $.get(Routing.generate('get_nb_emails_to_treat'), function (data) {
            emailCounter.html(data);
        });
    }
    // compteur campagne
    const contactCounter = $('.nb-contacts-to-treat');
    if (contactCounter.length > 0) {
        $.get(Routing.generate('get_nb_contacts_to_treat'), function (data) {
            contactCounter.html(data);
        });
    }

    submitLinks();

    if ($('#filterPos').length > 0) {

        $('#filterPos').keyup(function () {
            let keyword = $(this).val();
            $('#listPos .pad').each(function () {
                let name = $(this).find('.name').html().toLowerCase();
                if (name.indexOf(keyword.toLowerCase()) === -1) {
                    $(this).addClass('--hidden');
                } else {
                    $(this).removeClass('--hidden');
                }
            });
            $('#listPos .line').each(function () {
                let name = $(this).find('.name').html().toLowerCase();
                if (name.indexOf(keyword.toLowerCase()) === -1) {
                    $(this).addClass('--hidden');
                } else {
                    $(this).removeClass('--hidden');
                }
            });
        });
    }

    if ($('#filterOrigin').length > 0) {

        $('#filterOrigin').keyup(function () {
            let keyword = $(this).val();
            $('#listOrigins .pad').each(function () {
                let name = $(this).find('.name').html().toLowerCase();
                if (name.indexOf(keyword.toLowerCase()) === -1) {
                    $(this).addClass('--hidden');
                } else {
                    $(this).removeClass('--hidden');
                }
            });
        });
    }

    //select2 + pré-remplissage si multiple
    let filtersSelects = ['#channel', '#interest', '#originFilter', '#selectOperator', '#select2seller', '#select2pos', '#select2posDisabled', '#evolution-range'];

    for (let i = 0; i < filtersSelects.length; i++) {

        let id = filtersSelects[i];
        let select = $('select' + id);
        let selectedValue = select.data('selected');

        if (typeof $.fn.select2 === 'function') {
            select.select2({
                placeholder: $(this).data('placeholder'),
                allowClear: true,
                width: 160
            });
            if (select.prop('multiple') &&  selectedValue !== undefined && selectedValue !== null && selectedValue !== '') {
                selectedValue = selectedValue.toString();
                $(id).select2('val', selectedValue.split(','));
                $(id).trigger('render');
            }
            $(".pos-select-email-libelle, .pos-select-phone-libelle").select2({
                allowClear: true,
            });
        }
    }



    if ($('#contentFilter').length > 0) {
        loadSellersList($('#contentFilter #select2pos'));
        loadMultipleSelect($('#select2seller'));
    }

    hideProInfo();

    $('.data .hide-data').click(function (event) {
        event.preventDefault();
        hideData($(this));
    });

    $('.data .show-data').click(function (event) {
        event.preventDefault();
        showData($(this));
    });

    $('#openUserMenu .button-icon').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass("active");
        $('#userMenu').toggleClass("active");
    });

    $(document).click(function (event) {
        if (!$(event.target).closest('#userMenu').length && !$(event.target).is('#openUserMenu')) {
            $('#openUserMenu').removeClass("active");
            $('#userMenu').removeClass("active");
        }
    });

    $('.close-flash').click(function (event) {
        event.preventDefault();
        $(this).parent('.flash-message').addClass('--hidden');
    });

    customForm();
    applyCti();
    closeWindow();

    $('.--confirm-action').click(function (event) {
        event.preventDefault();
        let message = translate('crm.public.are_you_sure');
        if ($(this).attr('data-message-confirmation')) {
            message = $(this).data('message-confirmation');
        }
        openConfirmation({'text': message, 'redirectUrl': $(this).attr('href')});
    });

    if ($('.flash-success:not(.js-flash-message-success)').length) {
        setTimeout(function() {
            $('.flash-success:not(.js-flash-message-success)').addClass('--hidden');
        }, 15000);
    }
    popLink();
    handlePopinAction();
    handleChangePos();

    // cacher tous les boutons calendar sauf le premier
    $('.lead-note-form.--with-appointment .note-content a.download-calendar').each(function(index) {
        if (index === 0) {
            $(this).addClass('--show-block');
        }
    });

    $('.download-calendar').on('click', function(e) {
        e.preventDefault();
        let id = $(this).data('lead-id');
        if ($('#value_user_id').val() > 0) {
            window.location = Routing.generate('seller_appointment_download_calendar', { 'id' : id });
        } else {
            $.get(Routing.generate('get_url_code', {
                'id': id,
            }), function (data) {
                let code = data.code;
                if (code) {
                    window.location = Routing.generate('url_short', { 'url_code' : code, 'url_action': 'downloadCalendar' });
                }
            });
        }
    });

    // menu
    const menu = $('#mainMenu');
    $('#toggleMainMenu').on('click', function() {
        if (menu.hasClass('--full') || menu.hasClass('--light')) {
            if (menu.hasClass('--light')) {
                menu.removeClass('--light');
                menu.addClass('--full');
                setCookie("mainMenuFull", '1', 7);
            } else {
                menu.removeClass('--full');
                menu.addClass('--light');
                setCookie("mainMenuFull", '0', 7);
            }
        } else {
            if (window.innerWidth > 1024) {
                menu.addClass('--light');
                setCookie("mainMenuFull", '0', 7);
            } else {
                menu.addClass('--full');
                setCookie("mainMenuFull", '0', 7);
            }
        }
    });
});
