const $ = require('jquery');

import moment from 'moment';
import {redirectWithParams, loadSellersList, translate} from './functions';

$(function () {

    $('#submit_filter.--default').click(function (e) {
        e.preventDefault();
        redirectWithParams();
    });

    $('#reset_filter.--default').click(function(e) {
        e.preventDefault();
        let start = new Date();
        let end = new Date();
        if ($(this).hasClass('reset-stats')) {
            start.setDate(1);
        }
        $( "#filter-range" ).daterangepicker('setRange', {'start': start, 'end': end });
        let elementsToReset = ['#select2pos', '#select2seller', '#interest', '#selectOperator', '#channel', '#originFilter', '#libelles'];

        // reset à [] si multiple, reset à null sinon
        for (let i = 0; i < elementsToReset.length; i++) {
            let element = $(elementsToReset[i]);

            if (element.prop('multiple')) {
                element.val([]).trigger('change');
            } else {
                element.val(null).trigger('change');
            }
        }

        $('#submit_filter.--default').trigger('click');
    });

    $('#select2pos').change(function () {
        if ($(this).val() !== 'custom') {
            if ($('#submit_filter').length === 0 && $('#search_search').length === 0) {
                redirectWithParams(true, $(this));
            }
            else {
                loadSellersList(this);
                loadOriginsList();
            }
        }
    });
    loadOriginsList();

    $('#contentFilter a.filter').click(function (event) {
        event.preventDefault();
        redirectWithParams();
    });

    //si contentFilter sur la page : attention aux tris des colonnes
    if ($('#contentFilter').length >= 1) {
        $('table th.sort a').click(function (event) {
            event.preventDefault();
            redirectWithParams(true, $(this));
        });
    }

});

function loadOriginsList() {

    let posId = $('#select2pos').val();
    if (posId === '') {
        posId = 0;
    }
    let originSel = $('select#originFilter');
    if (originSel.length === 0) {
        return;
    }
    let url = $(originSel).data('url');
    let selected = originSel.data('selected');

    if (selected !== undefined) {
        selected = selected.toString().split(',');
    }

    url = url + '/' + posId;

    $.get(url, function (data) {
        originSel.empty();
        $.each(data, function (key, value)
        {
            let elt = $('<option value=' + value.id + '>' + value.label + '</option>');
            if (selected != null) {
                for (let i = 0; i < selected.length; i++) {
                    let origin = selected[i];
                    if (origin === key) {
                        elt.attr('selected');
                        break;
                    }
                }
            }
            originSel.append(elt);
        });
        originSel.select2('val', selected);
        originSel.trigger('render');

    });
}

$(function() {
    $('#select2pos').on('change', function() {
        $("#select2seller").select2('val', '');
    });
    let clearButtonText = false;
    if ($('#searchForm').length > 0) {
        clearButtonText = translate('crm.public.clear');
    }
    //Widget range du bloc filtre
    $("#filter-range").daterangepicker({
        initialText: translate('crm.public.select_period'),

        dateFormat: 'dd/mm/yy',

        datepickerOptions : {
            numberOfMonths: 2,
            dayNamesMin: [ translate('crm.public.sunday'), translate('crm.public.monday'), translate('crm.public.tuesday'), translate('crm.public.wednesday'), translate('crm.public.thursday'), translate('crm.public.friday'), translate('crm.public.saturday') ],
            monthNames: [ translate('crm.public.january'), translate('crm.public.february'), translate('crm.public.march'), translate('crm.public.april'), translate('crm.public.may'), translate('crm.public.june'), translate('crm.public.july'), translate('crm.public.august'), translate('crm.public.september'), translate('crm.public.october'), translate('crm.public.november'), translate('crm.public.december') ],
        },

        applyButtonText: translate('crm.public.apply'),
        clearButtonText: clearButtonText,
        cancelButtonText: translate('crm.public.cancel'),
        presetRanges: [{
            text: translate('crm.public.today'),
            dateStart: function() { return moment(); },
            dateEnd: function() { return moment(); }
        },{
            text: translate('crm.public.seven_last_days'),
            dateStart: function() { return moment().subtract(7, 'days'); },
            dateEnd: function() { return moment(); }
        },{
            text: translate('crm.public.since_beginning_week'),
            dateStart: function() { return moment().startOf('isoweek'); },
            dateEnd: function() { return moment(); }
        },{
            text: translate('crm.public.since_beginning_month'),
            dateStart: function() { return moment().startOf('month'); },
            dateEnd: function() { return moment(); }
        },{
            text: translate('crm.public.since_beginning_year'),
            dateStart: function() { return moment().startOf('year'); },
            dateEnd: function() { return moment(); }
        }]
    });

    function showHideUserFilterSelectbox() {
        if ($('#select2pos').length === 0) {
            $('#userFilter').removeClass('hidden');
            $('#select2seller').attr('disabled', false);
            return;
        }
        if (typeof $('#select2pos :selected').attr('value') == 'undefined' || $('#select2pos :selected').attr('value') === "") {
            $('#userFilter').addClass('hidden');
            $('#select2seller').attr('disabled', true);
        }
        else {
            $('#userFilter').removeClass('hidden');
            $('#select2seller').attr('disabled', false);
        }
    }
    $('#select2pos').on('change', function() {
        showHideUserFilterSelectbox();
    });
    //Affiche la selectbox Utilisateur dans le filtre si on a selectionné un Point de vente
    showHideUserFilterSelectbox();
});
