const $ = require('jquery');
const {translate} = require("./functions");

$(function(){
    $('#error_send').on('click', function() {
        let data = $('.error-form').serialize();
        $.post(
            Routing.generate('error_send'),
            {
                data
            }
        )
            .done(function(response) {
                if (response.code === 200) {
                    $('.js-flash-message-success .text').html(translate('crm.error.send_success'));
                    $('.js-flash-message-success').removeClass('--hidden');
                    setTimeout(function() {
                        window.close();
                    }, 2000);
                    setTimeout(function() {
                        window.location.href = '/';
                    }, 3000);
                } else {
                    $('.js-flash-message-error .text').html(response.message);
                    $('.js-flash-message-error').removeClass('--hidden');
                }
            })
            .fail(function() {
                $('.js-flash-message-error .text').html(translate('crm.error.send_error'));
                $('.js-flash-message-error').removeClass('--hidden');
            })
    });
    $('#error_close').on('click', function(event) {
        event.preventDefault();
        setTimeout(function() {
            window.location.href = '/';
        }, 1000);
    })
});