const $ = require("jquery");
const {updateHeaderPaginationInfo} = require("./tables");



$(function () {
    function handleExternalBillingPaginationLinkAction() {
        let paginationLink = $('.paginationLink.--external-billing');

        if (paginationLink.length > 0) {

            let loader = $('.common-loader');
            let tableLinesWrapper = $('.bill-table tbody');

            paginationLink.off('click').on('click', function () {
                let page = $(this).attr('data-page');
                getExternalBillsRequest(page);
            });

            function getExternalBillsRequest(page) {
                loader.removeClass('--hidden');
                tableLinesWrapper.empty();

                let baseUrl = currentUrl.split("?")[0];
                let url = `${baseUrl}?page=${page}`;

                history.pushState(null, "", url);

                $.get(url)
                    .done(function (response) {
                        loader.addClass('--hidden');
                        tableLinesWrapper.html(response.template);
                        updateHeaderPaginationInfo(response);
                    });
            }
        }
    }

    handleExternalBillingPaginationLinkAction();
});
