const $ = require('jquery');

import {openConfirmation, translate} from './functions';

$(function() {
    let cti_use = $('#cti_use').data('cti');
    let ajaxRunning = false;

    if (cti_use === 'viadialog') {
        $("body").on('click', '.begin-call', function (e) {
            e.preventDefault()

            if (ajaxRunning) {
                return false;
            }

            ajaxRunning = true;

            $.ajax({
                url: $(this).attr('href'),
                type: 'GET',
            }).done(function (response) {
                ajaxRunning = false;
            }).error(function(response){
                let errorMessage = response.responseText;
                openConfirmation({'text': errorMessage, 'alert': true});
                ajaxRunning = false;
            });

            return false;
        });
    }
    else if (cti_use === 'viaflow') {
        $("body").on('click', '.begin-call', function (e) {
            e.preventDefault()

            if (ajaxRunning) {
                return false;
            }

            ajaxRunning = true;

            let ctiCallUrl = $(this).data('href');
            let callData = {
                posId: $(this).data('pos-id'),
            };

            $.post(ctiCallUrl, callData, function(data) {
                ajaxRunning = false;
            }).done(function (response) {
                ajaxRunning = false;
                if (response !== 'Appel lancé et enregistré') {
                    openConfirmation({'text': response, 'alert': true, 'confirmText': translate('crm.form.lead.confirmation.close')});
                }
            }).fail(function(response){
                let errorMessage = JSON.parse(response.responseText);
                openConfirmation({'title': translate('crm.error.title'), 'text': errorMessage, 'alert': true, 'confirmText': translate('crm.form.lead.confirmation.close')});
                ajaxRunning = false;
            });
        });
    }
    else if (cti_use === 'onc') {
        $("body").on('click', '.begin-call', function (e) {
            var num = $(this).data('num');
            e.preventDefault();
            window.external.Rcp2HtmlTabMakeCall($.trim(num), null);
            return false;
        });
    }
})