const $ = require('jquery');

import {openConfirmation, translate} from './functions';

$(function() {
    let cti_use = $('#cti_use').data('cti');
    let ajaxRunning = false;
    let transfertInterval = null;

    hideTransfertWindowsHandler();

    if (cti_use === 'viadialog') {
        $("body").on('click', '.begin-transfer', function (e) {
            e.preventDefault()

            if (ajaxRunning) {
                return false;
            }

            ajaxRunning = true;

            let num = $(this).data('num');
            $.ajax({
                url: $(this).attr('href'),
                type: 'GET',
            }).done(function () {
                $("#transferWindow").removeClass('--hidden');
                $("#transferTitle").html(num);
                ajaxRunning = false;
            });

            return false;
        });
    }
    else if (cti_use === 'viaflow') {
        $("body").on('click', '.begin-transfer', function (e) {
            e.preventDefault()

            if (ajaxRunning) {
                return false;
            }

            ajaxRunning = true;

            let num = $(this).data('num');
            $.get($(this).attr('href'), function(){
                if (transfertInterval !== null) {
                    clearInterval(transfertInterval);
                }
                $("#transferWindow").removeClass('--hidden');
                $("#transferTitle").html(num);
                ajaxRunning = false;
            })
                .fail(function(response){
                    let errorMessage = response.responseText.includes('could not find a call context with userId') ?
                        translate('crm.lead.transfer_not_possible') :
                        response.responseText
                    ;
                    openConfirmation({'title': translate('crm.error.title'), 'text': errorMessage, 'alert': true, 'confirmText': translate('crm.form.lead.confirmation.close')});
                    ajaxRunning = false;
                })
            ;
        });
    }
    else if (cti_use === 'onc') {
        $("body").on('click', '.begin-transfer', function (e) {
            let num = $(this).data('num');
            e.preventDefault();
            window.external.Rcp2HtmlTabTransferCall($.trim(num));
            return false;

        });
    }

    function hideTransfertWindowsHandler()
    {
        $('#hidetransferWindow').click(function(){
            $("#transferWindow").addClass('--hidden');
        })
    }

    $(".transfer-box").click(function () {
        if ($(this).hasClass('disabled')) {
            return false;
        }
        let button = $(this);
        button.addClass('disabled');
        setTimeout(function() {
            button.removeClass('disabled');
        }, 1000);
        if ($(this).attr('id') === 'validate-transfer') {
            //Si on transfert un appel, on coche le bouton Appel transferé
            let elem = $('#lead_transfered');
            if (elem.length > 0) {
                elem.prop('checked', true);
            }
        }

        $.get($(this).attr('href'), function (data) {
            button.removeClass('disabled');
            if (data !== true) {
                openConfirmation({'title': translate('crm.error.title'), 'text': data, 'confirmText': translate('crm.common.close'), 'alert': true});
            }
        })

        return false;
    });
})