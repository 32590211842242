const $ = require('jquery');

$(function () {
    handleTitleChoiceInput();
});

function handleTitleChoiceInput() {
    handleTitleChoiceInputBgColor();

    function handleTitleChoiceInputBgColor (){
        const titleInput = $('#seller_title, #lead_customer_title, #call_user_title, #profile_title, #contact_customer_title');
        defineBgColor(titleInput);
        titleInput.change(function(){
            defineBgColor($(this));
        });

        function defineBgColor(titleInput) {
            const spanElement = titleInput.siblings('.slider.round');
            if (titleInput.prop('checked') === true) {
                spanElement.removeClass('--bg-clear-blue');
                spanElement.addClass('--bg-pink');
            } else {
                spanElement.removeClass('--bg-pink');
                spanElement.addClass('--bg-clear-blue');
            }
        }
    }
}