import $ from "jquery";

export function updateHeaderPaginationInfo(response)
{
    let paginationInfo = response.paginationListData;

    let previousPage = $('.paginationLink.previous');
    let nextPage = $('.paginationLink.next');
    let firstItemNumber = $('.firstItemNumber');
    let lastItemNumber = $('.lastItemNumber');
    let totalCount = $('.totalCount');

    if (paginationInfo.previous){
        previousPage.removeClass('--hidden');
        previousPage.attr('data-page', paginationInfo.previous)
    } else {
        previousPage.addClass('--hidden');
    }

    if (paginationInfo.next){
        nextPage.removeClass('--hidden');
        nextPage.attr('data-page', paginationInfo.next)
    } else {
        nextPage.addClass('--hidden');
    }

    if (paginationInfo.firstItemNumber) {
        firstItemNumber.html(paginationInfo.firstItemNumber)
    } else {
        firstItemNumber.html(0)
    }

    if (paginationInfo.lastItemNumber) {
        lastItemNumber.html(paginationInfo.lastItemNumber)
    } else {
        lastItemNumber.html(0)
    }

    if (paginationInfo.totalCount) {
        totalCount.html(paginationInfo.totalCount)
    } else {
        totalCount.html(0)
    }
}

export function resetPagination(requestParameters)
{
    requestParameters.page = 1;
}


export function handleSearchInputChangeAction(searchInput, callback, requestParameters)
{
    let timeoutId;

    searchInput.keyup(function(e) {
        let $this = $(this);
        clearTimeout(timeoutId);

        timeoutId = setTimeout(function() {
            requestParameters[$this.attr('id')] = $this.val();
            callback($this);
        }, 200);
    });


    searchInput.change(function (){
        if ($(this).hasClass('datepicker')) {
            requestParameters[$(this).attr('id')] = $(this).val();
            callback($(this));
        }
    })
}

export function handleSearchSelectChangeAction(selectInput, callback, requestParameters)
{
    selectInput.change(function(){
        requestParameters[$(this).attr('id')] = $(this).val();
        callback($(this));
    })
}

export function handleSortingAction(table, callback, requestParameters)
{
    const sort_ASC_arrows = table.find('.sort-asc');
    const sort_DESC_arrows = table.find('.sort-desc');

    sort_ASC_arrows.click(function(){
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
            delete requestParameters[$(this).data('sort-element-name')];
        } else {
            $(this).addClass('selected');
            table.find('[data-sort-element-name=' + $(this).data('sort-element-name') + '].sort-desc').removeClass('selected');
            requestParameters[$(this).data('sort-element-name')] = 'ASC';
        }
        callback($(this));
    })

    sort_DESC_arrows.click(function(){
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
            delete requestParameters[$(this).data('sort-element-name')];
        } else {
            $(this).addClass('selected');
            table.find('[data-sort-element-name=' + $(this).data('sort-element-name') + '].sort-asc').removeClass('selected');
            requestParameters[$(this).data('sort-element-name')] = 'DESC';
        }
        callback($(this));
    })
}