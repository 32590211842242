const $ = require("jquery");
const {checkPhoneFields, openConfirmation, translate} = require("./functions");

const container = $('.profile-phones');

if (container.length > 0) {
    profilePhoneFieldsValidation();

    function profilePhoneFieldsValidation()
    {
        container.find('form').submit(function(){
            return checkPhoneFields('profile');
        });
    }
}

//TODO loader
const chooseCalendar = $('.choose-calendar');
if (chooseCalendar.length > 0) {
    const calendars = $('.calendars');
    const lines = calendars.find('.lines');
    const loader = calendars.find('.common-loader');
    chooseCalendar.on('click', function() {
        lines.addClass('--hidden');
        loader.removeClass('--hidden');
        const url = Routing.generate('calendar_choose', {'type': $(this).data('type')});
        $.post( url, { id: $(this).data('id')})
            .done(function( response ) {
                if (response.success) {
                    window.location = Routing.generate('account')
                } else {
                    lines.removeClass('--hidden');
                    loader.addClass('--hidden');
                    openConfirmation({'title': translate('crm.error.title'), 'text': translate('app.calendar.save_error'), 'alert': true, 'confirmText': translate('crm.common.close')});
                }
            })
            .fail(function(response){
                lines.removeClass('--hidden');
                loader.addClass('--hidden');
                let errorMessage = JSON.parse(response.responseText);
                openConfirmation({'title': translate('crm.error.title'), 'text': errorMessage, 'alert': true, 'confirmText': translate('crm.common.close')});
            });
    })
}

const calendarType = $('#calendarType');
if (calendarType.length > 0) {
    const calendarSync = $('#syncCalendar');
    calendarType.select2({
        placeholder: $(this).data('placeholder'),
        allowClear: true
    });
    calendarType.on('change', function() {
        if ($(this).val()) {
            calendarSync.removeClass('--hidden');
        } else {
            calendarSync.addClass('--hidden');
        }
    })
    calendarSync.on('click', function() {
        window.location = calendarType.find('option:selected').data('url');
    });
}
const calendarId = $('#calendarId');
if (calendarId.length > 0) {
    const calendarUpdate = $('#updateCalendar');
    calendarId.select2({
        placeholder: $(this).data('placeholder'),
        allowClear: true
    });
    calendarId.on('change', function() {
        if ($(this).val()) {
            calendarUpdate.removeClass('--hidden');
        } else {
            calendarUpdate.addClass('--hidden');
        }
    })
    calendarUpdate.on('click', function() {
        window.location = calendarId.find('option:selected').data('url');
    });
}
