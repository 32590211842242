const $ = require('jquery');

function redirectWithParams(element) {
    let pos = $('#select2pos').val();
    if (pos === undefined)
    {
        pos = $('#select2posSeller').val();
    }

    let seller = '';
    if ($('#select2seller').val() != null && $.isArray($('#select2seller').val())) {
        seller = $('#select2seller').val().join(',');
    }

    let route = $('#contentFilter .route').val();

    let data = [];

    if (typeof pos != 'undefined') {
        data['pos'] = pos;
    }

    if (typeof seller != 'undefined') {
        //on ne garde pas l'info sur le vendeur si c'est le pos qu'on change
        if (typeof element == 'undefined' || element.hasClass('pos') === false) {
            data['seller'] = seller;
        }
    }

    window.location = Routing.generate(route, data);
}

$(function () {
    $('#submit_filter.--pos-seller').click(function (e) {
        e.preventDefault();
        redirectWithParams();
    });

    $('#reset_filter.--pos-seller').click(function(e) {
        e.preventDefault();
        const defaultPos = $('#contentFilter').find('.default-pos').val();
        $('#select2pos').val(defaultPos).trigger('change');
        $('#select2seller').val(null).trigger('change');
        $('#submit_filter.--pos-seller').trigger('click');
    });

    // si un seul pdv, on désactive
    if ($('#select2pos').children().length <= 1) {
        $('#select2pos').prop('disabled', true);
    }

    let allowClear = $("#select2pos").data('allow-clear');
    if (allowClear === '') {
        allowClear = true;
    }
    $("#select2pos").select2({
        allowClear: allowClear,
        width: 200
    });

    $('#select2pos').change(function () {
        loadSellersList(this);
    });

    $('#select2pos').on('change', function() {
        $("#select2seller").select2('val', '');
    });
});

function loadSellersList(select) {

    let posId = $('#select2pos').val();
    let sellerSel = $('#select2seller');
    let url = $(select).data('url');
    let selected = sellerSel.data('selected');

    if (selected !== undefined) {
        selected = selected.toString().split(',');
    }

    if (posId === undefined || posId === null || posId.length === 0)
        return;
    url = url + '/' + posId + '/' + '0';

    $.get(url, function (data) {
        sellerSel.empty();
        $.each(data, function (key, value)
        {
            let elt = $('<option value=' + key + '>' + value + '</option>');
            if (selected != null) {
                for (let i = 0; i < selected.length; i++) {
                    let seller = selected[i];
                    if (seller === key) {
                        elt.attr('selected');
                        break;
                    }
                }
            }
            sellerSel.append(elt);
        });

        loadMultipleSelect(sellerSel);
    });
}

function loadMultipleSelect(select) {
    let selected = select.data('selected');
    if (selected !== undefined && selected !== null) {
        selected = selected.toString();
        $('#select2seller').select2('val', selected.split(','));
        $('#select2seller').trigger('render');
    }
}
